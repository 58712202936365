import {ActivityPaidSuccess} from "../pages/ActivityPaidSuccessPage/activityPaidSuccess";

export const docsLinks = {
  privacy_policy: {
    name: 'Политика конфиденциальности',
    name2: 'Политикой конфиденциальности',
    registartion_name: 'Политику конфиденциальности разработчика',
    link: 'https://files.coacti.com/docs/legal/CoActivity_privacy_policy.pdf'
  },
  terms_of_buy_activities: {
    name: 'Условия размещения и покупки активностей',
    name2: 'Условиями размещения и покупки активностей',
    link:
      'https://files.coacti.com/docs/legal/CoActivity_terms_of_placement_and_purchase.pdf'
  },
  terms_of_use: {
    name: 'Пользовательское соглашение',
    name2: 'Пользовательским соглашением',
    registartion_name: 'Пользовательское соглашение',
    link: 'https://files.coacti.com/docs/legal/CoActivity_user_agreement.pdf'
  },
  terms_of_cookies: {
    name: 'файлы cookie',
    link: 'https://files.coacti.com/docs/legal/CoActivity_privacy_policy.pdf'
  }
};

export const BASIC_ROUTES = {
  courses: '/courses',
  paymentDetails: '/payment-details'
};

export const ROUTES = {
  home: '/',
  profile: '/profile',
  profileShow: '/profile/:profileId',
  profileEdit: '/profile/edit',
  profileResetPass: '/profile/reset-pass',
  register: '/register',
  login: '/login',
  courses: BASIC_ROUTES?.courses,
  coursesById: `${BASIC_ROUTES?.courses}/:id`,
  coursesByIdOverview: `${BASIC_ROUTES?.courses}/overview/:id`,
  coursesByIdAboutShort: `${BASIC_ROUTES?.courses}/about-short/:shortActivityId`,
  coursesByIdAcceptWithdraw: `${BASIC_ROUTES?.courses}/accept-withdraw/:acceptWithdrawId`,
  coursesSuccessPayment: `${BASIC_ROUTES?.courses}/payment_success/:activityId`,
  coursesTournamentTable: `${BASIC_ROUTES?.courses}/:id/tournament`,
  apps: `/apps`,
  transferCourseRequest: '/#transferCourseRequest',
  price: '/#price',
  launchCourse: '/#launchCourse',
  forAuthors: '/#forAuthors',
  forUsers: '/#forUsers',
  forBusiness: '/#forBusiness',
  myCourses: '/my-courses',
  verify: '/verify',
  activitiesConfirm: '/activitiesconfirm',
  activitiesPaymentSuccess: '/activitiespayment-success',
  activitiesJoinSuccess: '/activitiesjoin-success',
  activitiesPaymentDetailsById: `${BASIC_ROUTES?.paymentDetails}/:id`,
  recovery: '/recovery',
  resetPassword: '/reset_password',
  resetEmail: '/reset_email',
  addRequisite: '/requisite/add',
  editRequisite: '/requisite/:requisiteId',
  privacyPolicy: '/privacy_policy',
  termsOfBuyActivities: '/terms_of_placement_and_purchase',
  termsOfUse: '/user_agreement',
  error500: '/error-500',

  // роуты для flow создания курсов
  forCreator: {
    myCourses: '/creator/my-courses',
    myCoursesNew: '/creator/new',
    newCourse: '/creator/new-course',
    newMarathon: '/creator/new-marathon',
    draftActivityEdit: '/creator/draft-editing/:draftEditActivityId',
    stepsSettings: '/creator/steps-settings/:draftEditActivityId',
    stepsCreator: '/creator/activity/:activity_id/create-steps',
    draftActivity: '/creator/draft/:draftActivityId',
    draftActivitySettings: '/creator/draft-settings/:draftActivitySettingsId',
    draftActivityAuthor: '/creator/draft-author/:draftActivitySettingsId',
    draftActivityAuthorNew:
      '/creator/draft-author-new/:draftActivitySettingsId',
    draftActivityAuthorEdit:
      '/creator/draft-author-edit/:draftActivitySettingsId',
    chooseRequesite: '/creator/choose-requisite/:draftActivitySettingsId',
    checkPaymentSettingsNew: '/creator/check-settings/:draftActivitySettingsId',
    almostDone: '/creator/almost-done/:draftActivitySettingsId',
    done: '/creator/done/:activityId'
  }
};

export const APPLE_APP_LINK =
  'https://apps.apple.com/ru/app/coactivity/id1596041521';
export const GOOGLE_APP_LINK = 'https://coacti.com/app';
// export const GOOGLE_APP_LINK = "https://play.google.com/store/apps/details?id=io.dnateam.coactivity";


export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
